import React from 'react';
import '../css/Modal.css';

function WeddingBellsModal(props) {
    var showHideClassName = props.show ? "modal display-block" : "modal display-none";

    return (
         <div className={showHideClassName}>
      <div className="modal-main">
      <div className="close-button"><i class="fas fa-times-circle close-icon modal-close" onClick={() => props.hideModal("wedding-bells")}></i></div>
        <div className="modal-header-div help-title">Demo Web Application</div>
                <p>This webpage is a for a fictional company called Wedding Bells</p>
                <p>To view webpage click on the company logo.</p>
                <div><a href="/wedding-bells/index.html"><img className="modal-image" src="Rectitude_Demo_Images/WeddingBells2.png"/></a></div>
      </div>
    </div>
  );
}

export default WeddingBellsModal;
