import React from 'react';
import '../css/faq.css';

export default function FAQ() {

    const formatDiv = {
        height:"100px"
    }

  return (
    <div>
        <div style={formatDiv}></div>
        <div className="container stop">
<h1>Frequently Asked Questions</h1>
<div className="row stop">
     <div className="row stop">
    <div className="col-md-12 formatFaq stop">
      <p><b>Q.</b> What do I get when I purchase the website creation service?</p>
      <p><b>A.</b> When you purchase the website creation service, what you get depends on what you need.  E-commerce website will include a catalog so your product can be displayed if necessary.  Also, it will include an access payment portal such as PayPal.  Cost generally increases the more unique features your website has.</p>
      <br/>
      <p><b>Q.</b>  How long does it take for a website to be created?</p>
      <p><b>A.</b> Website completion dates depend on what features and the complexity of the customer’s needed application.  Also, the completion date will be effected by how fast the customer gives our developers requested documents such as item description, pictures, contracts etc.</p>
      <br/>
      <p><b>Q.</b> Where can I meet with the tutor?</p>
      <p><b>A.</b> Our tutors will only meet at public or college campus libraries during between 8 a.m. – 8 p.m.</p>
      <br/>
      <p><b>Q.</b>  When are our tutors available?</p>
      <p><b>A.</b>  Our tutors are available Tuesday, Wednesday, Saturday, and Sunday.  Tutors can meet outside these times in rare occasions.</p>
      <br/>
      <p><b>Q.</b>  What courses do our tutors cover?</p>
      <p><b>A.</b>  we cover a variety of Software Engineering courses.  Some of those courses include, C#, Java, JavaScript, PHP, HTML, CSS3, AJAX, JQUERY, Bootstrap, most popular SQL relational Databases, MS Entity Framework, Object Relational Mappers, UML, Algebra, Beginner’s Discreet Mathematics, Apache Servers, IIS servers, Agile Software Development, Object Oriented Programming Design structures, Data Structures, English (that is related to software engineering), and Most Microsoft Office Applications. We will be adding Node JS and No SQL databases soon.</p>
      <br/>
      <p><b>Q.</b> Do you provide Online Tutoring?</p>
      <p><b>A.</b>  We do provide online tutoring.  Our tutors use either Skype or google hang out to communicate live over the internet.  Also, correspondence can be done using email.  Clients must send the tutor a copy of the instructor’s assignment instructions so the tutor can prepare for the tutoring session.</p>
      <br/>
      <p><b>Q.</b>  How much does a tutoring session cost?</p>
      <p><b>A.</b>  Tutoring session cost $20/hr. for a one on one in person session and $15/hr. for a one on one online session.  Email Correspondence tutoring also cost $15/hr.</p>
      <br/>
      <p><b>Q.</b>  How is payment made for tutoring sessions?</p>
      <p><b>A.</b>  Payments for in person tutoring can be made in person before or after the tutoring session begins.  Online tutoring sessions are paid using PayPal and the payment must be made before the tutoring session begins.</p>
     </div>
     </div>
     </div>
</div>
    </div>
  )
}
