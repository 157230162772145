import React from 'react';
import '../css/pageNotFound.css';

export default function PageNotFound() {

    const formatDiv = {
        height:"100px"
    }

  return (
    <div>
       <div style={formatDiv}></div>
        <div class="container-fluid backGroundPicture height stop">
<h1>404: Page not found.</h1>
</div>
    </div>
  )
}
