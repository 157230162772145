import React from 'react';
import '../css/webHosting.css';
import {Link} from 'react-router-dom';

export default function WebHosting() {

    const formatDiv = {
        height:"100px"
    }

  return (
    <div>
        <div id="webHosting" className="stop">
	<div className="jumbotron stop">
	    <h1 className="header-format">AWS WEBHOSTING SERVICES</h1>
      <p className="aws-text">Rectitude Solutions provides affordable AWS web hosting services.</p>
	    <br/>
       <Link to="/ContactUs"  className="btn btn-info" role="button">Contact Web Hosting Sales</Link>

	</div>
</div>
    </div>
  )
}
