import React from 'react'

export default function AboutUs() {

    const h1 = {textAlign:"center"}

    const formatPage ={fontSize:"20px"}

    const formatDiv = {
        height:"100px"
    }

  return (
<div className="container stop">
<div className="aboutUs-format"></div>
<h1 style={h1}>ABOUT US</h1>
<div className="row stop">
     <div className="row stop">
    <div className="col-md-12 stop">
      <p className="formatPage stop">Rectitude Solutions was founded by Centennial College Software Engineering graduate Kevin Haynes in 2017.  Rectitude (Righteous) Solutions is commited to providing top quality service at a low consumer cost.  We provide 2 main services with a third one on the way.  We provide Website creation services which include fully responsive websites which look good on all electronic devices.  We also provide tutoring services that is geared toward College and 1st and 2nd year University Software Engineering Students.  Our third service which we will be providing is our webhosting service.  Rectitude Solutions is in the process of providing affordable webhosting packages for our customers.  Our motto here at Rectitude Solutions is "We Help You Find the Right Solutions".  We at Rectitue Solutions hope to provide customers with quality service for many years while fulfilling our social responsibility.  We hope our company can provide your needs.</p><br/>
      <p className="stop" style={formatPage}>Kevin Haynes<br/>
      CEO</p>
     </div>
     </div>
     </div>
</div>


  )
}
