import React, {useState,useEffect}  from 'react';
import '../css/webCreation.css';
import { useTransition, animated, config } from 'react-spring';
import {Link} from 'react-router-dom';
import WeddingBellsModal from './weddingBellsModal';
import ILSModal from './ILSModal';
import ShowLoveModal from './ShowLoveModal';
import $ from 'jquery';

export default function WebCreation() {

    const formatDiv = {
        height:"100px"
    }

    const [webCreationText, setWebCreationText] = useState("web-service-header");
    const [webServiceText, setWebServiceText] = useState("web-service-text");
    const [webCreationImage1, setWebCreationImage1] = useState("demo_image1 img-thumbnail");
    const [webCreationImage2, setWebCreationImage2] = useState("demo_image2 img-thumbnail");
    const [webCreationImage3, setWebCreationImage3] = useState("demo_image3 img-thumbnail");

    const animationClass = [
      "serviceText-slideUp",
      "serviceText-fadeIn",
      "servicesImage-slideInRight",
      "servicesImage-slideInRight2",
      "servicesImage-slideInRight3"
    ];

    const [index, set] = useState(0);
    const [showWedding, setShowWedding] = useState(false);
    const [showILS, setShowILS] = useState(false);
    const [showSLP, setShowSLP] = useState(false);

    const transitions = useTransition(index,{
      key: index,
      from:{transform:"scale(0)",opacity:0},
      enter:{transform:"scale(1)",opacity:1},
      leave:{opacity:0 },
      config:{duration:3000},
      onRest: (_a, _b, item) => {
        if (index === item) {
          const t = setInterval(()=> set(state => (state + 1) % slides.length),16000);
          clearTimeout(t);
        }
      },
      exitBeforeEnter: true,
    });

    const hideModal = (website) =>{
      if(website == "wedding-bells"){
        setShowWedding(false);
      }
      if(website == "ILS"){
        setShowILS(false);
      }
      if(website == "ShowLove"){
        setShowSLP(false);
      }
    }

    const showModal = (website) =>{
      if(website == "wedding-bells"){
      setShowWedding(true);
      }
      if(website == "ILS"){
        setShowILS(true);
        }
        if(website == "ShowLove"){
          setShowSLP(true);
          }
    }

    useEffect(()=>{
      const t = setInterval(()=> set(state=>(state + 1) % slides.length),16000);
      console.log("in useEffect");
      
  
      
      return ()=> clearTimeout(t)
    },[])

    const slides = [
      { id: 0, url: 'Rectitude_Demo_Images/WeddingBells_Demo.jpg'},
      { id: 1, url: 'Rectitude_Demo_Images/ILS_Demo.jpg'},
      { id: 2, url: 'Rectitude_Demo_Images/BurgerJoint_Demo.jpg'},
      { id: 3, url: 'Rectitude_Demo_Images/CityTours_Demo.jpg'},
      { id: 4, url: 'Rectitude_Demo_Images/XabusWebsite.jpg'},
      { id: 5, url: 'Rectitude_Demo_Images/Macadus.jpg'},
      { id: 6, url: 'Rectitude_Demo_Images/ShowLoveDemo.jpg'},
      { id: 7, url: 'Rectitude_Demo_Images/DetoximusDemo.jpg'}
    ];


$(window).scroll(function(){
	console.log($(this).scrollTop());

  if($(this).scrollTop() > 508){
    setWebCreationImage1("demo_image1 img-thumbnail "+animationClass[2]);
    setWebCreationImage2("demo_image2 img-thumbnail "+animationClass[3]);
    setWebCreationImage3("demo_image3 img-thumbnail "+animationClass[4]);
  
  if($(this).scrollTop() > 150){
      setWebCreationText("web-service-header "+animationClass[0]);
      setWebServiceText("web-service-text "+animationClass[1]);
     
  }
  }else if($(this).scrollTop() == 0){
    setWebCreationText("web-service-header");
    setWebServiceText("web-service-text");
    setWebCreationImage1("demo_image1 img-thumbnail");
    setWebCreationImage2("demo_image2 img-thumbnail");
    setWebCreationImage3("demo_image3 img-thumbnail");
  }
});

    const border = {border:"2px solid black"}

  return (
<div>
  <WeddingBellsModal show={showWedding} hideModal={hideModal}/>
  <ILSModal show={showILS} hideModal={hideModal}/>
  <ShowLoveModal show={showSLP} hideModal={hideModal}/>

<div id="webCreation" className="web-creation-container stop">
  <div className="formatDiv"></div>
  {/*<div className="grid-container">*/}
  <div className="web-creation-div infoText-slideIn">
<h1 className="header-format" style={{color:"#003366"}}>WEBSITE CREATION SERVICES</h1>
    <p className="web-creation-text"><b>Whether you need a website for your business or blog,</b></p>
	    <p className="web-creation-text"><b>Rectitude Solutions provides affordable website creation services.</b></p>
</div>

<div className="laptop-format-container">
<div className="laptop-div servicesImage-slideInRight">
 <div className="laptop-image-container"><img className="laptop-image" src="Rectitude_Logos/laptop.png"/></div>
 {transitions((style, i) => (

<animated.div
className="demo-container"
style={{...style}}>
<img className="demo-image" src={slides[i].url}/>
 </animated.div>
))}
</div>
</div>
	</div>
  {/*</div>end div-container */}
<div id="info-container" className="container-fluid border-top">
<div className="info stop">
		<div className="web-creation-services">
			<h2 className={webCreationText}>Rectitude Solutions Website Creation Services</h2>
			<p className={webServiceText}>Rectitude Solutions provides website creations services that at a affordable price.
			All website creation services include front and backend application creation plus Sreach Engine Optimization(SEO).
		 	We can create responsive websites from scratch or use a template that you provide.
			Our websites look great on all devices.
			Contact us so you can get your website started today.</p>
		</div>
</div>
<br/>



<div className="row">
<div className="col-md-12">
<h2 className="formStyle">Sample Websites Images</h2>
<p className="formStyle">(Click image to view demo)</p>
	</div>
</div>
<div className="row zIndex">
<div className="col-md-4">
  <div className="showLoveImage" onClick={()=>showModal("ShowLove")}>

	<img className={webCreationImage1} src="Rectitude_Demo_Images/ShowLoveDemo.jpg" alt="Show Love Productions Website Demo" />

</div>
</div>
<div className="col-md-4">

		<img className={webCreationImage2} src="Rectitude_Demo_Images/ILS_Demo.jpg" onClick={()=>showModal("ILS")}/>
</div>
<div className="col-md-4">
		<img className={webCreationImage3} src="Rectitude_Demo_Images/WeddingBells_Demo.jpg" onClick={()=>showModal("wedding-bells")}/>
</div>
</div>
</div>
</div>
  )
}
