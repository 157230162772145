import React from "react";
import '../css/Rectitude_StyleSheet.css';
import FadeTest2 from './FadeTest2';
//import NavBar from './NavBar';
import HomePageInfo from './HomePageInfo';
import '../css/navbar.css';


const Home2 = ()=>{
  
    const formatDiv = {
        height:"0px"
    }

return(
<div>
<div style={formatDiv}></div>
	<FadeTest2/>
    <HomePageInfo/>
    </div>
);
}

export default Home2;