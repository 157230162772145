import React, { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'
import {Link} from 'react-router-dom';
/*import '../css/animateB.css';
import useInterval from 'react-useinterval';*/
//import 'animate.css';
import '../css/faderTest2.css';
import $ from 'jquery';



const slides = [
  { id: 0, url: 'Rectitude_Logos/Pictures1/igor-miske-207639.jpg', text: 'Website Creation',infoText:"Let Rectitude Solutions provide all your Web Development needs."},
  { id: 1, url: 'Rectitude_Logos/Pictures1/ben-white-148430.jpg', text: 'IT Tutoring',infoText:"Achieve academic success with our IT Tutoring services."},
  { id: 2, url: 'Rectitude_Logos/Pictures1/WebHosting.jpg', text:'Web Hosting',infoText:"Host your website with our affordable AWS hosting package."}
  /*{ id: 3, url: 'photos/photo4.jpg', text:'Promote Your Sound'}*/
];

function FadeTest2(){

  const sliderStyles = {
          width:"100vw",
          height:"100vh",
          backgroundColor:"blue"
  }

  const [index, set] = useState(0);

  var x;

  const transitions = useTransition(index,{
    key: index,
    from:{opacity:0},
    enter:{opacity:1},
    leave:{opacity:0 },
    config:{duration:3000},
    onRest: (_a, _b, item) => {
      if (index === item) {
        const t = setInterval(()=> set(state => (state + 1) % slides.length),16000);
        clearTimeout(t);
      }
    },
    exitBeforeEnter: true,
  })



  useEffect(()=>{
    const t = setInterval(()=> set(state=>(state + 1) % slides.length),16000);
    console.log("in useEffect");
    

    
    return ()=> clearTimeout(t)
  },[])

  return(
    <div className="anmimted-container">
      
      <div className="decoration-div-blue"></div>
      <div className="decoration-div-black"></div>
      <div className="animated-text-container">
      <div className="animated-div-container">
      
      {transitions((style, i) => (

        <animated.div
        className="init-bg-v2"
        style={{
          ...style,
        backgroundImage:`url(${slides[i].url})`
        }}>
        </animated.div>
))}
 </div>

{transitions((style, i)=>(
  <animated.div className="animated-text"
  style={style}>
   <p>{slides[i].text}</p>
  <div className="service-info-text infoText-slideIn">
    <p>{slides[i].infoText}</p>
  </div>
</animated.div>
))}
</div>
      
     
      
      
      </div>
  )

}

export default FadeTest2;
