import React from 'react';
import '../css/blog.css';

export default function Blog() {

    const formatDiv = {
        height:"100px"
    }

  return (
    <div>
        <div id="fb-root"></div>
{(function(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "//connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v2.10&appId=466658200400724";
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'))}
<div class="container formatBlog stop">
<div className="blog-format"></div>
<h1>Technology & Education</h1>
<h5>(by Kevin Haynes)</h5><br/>
<div className="row stop">
     <div className="row stop">
       <div className="col-md-12 stop">

    <p className="formatPage">Many of us have had to sit through a class when we were in grade school, High School, or College watching the clock and wishing that it would move faster.  Older students must sit through lectures take notes and try to keep their minds off other classes or errands that they need to do.  Many students spend countless hours in the study hall or with a tutor just to barely pass. For these reasons I feel that with the proper use of technology, students can achieve greater academic success then they are currently getting.</p>
    <br/>
    <p className="formatPage">If you are like me, you have sat through your share of lectures wishing that time would “hurry up” even though you knew that the information you were getting was vital for you to achieve your academic success.  The use of technology in the class room makes the class more interactive.  I always enjoyed a class were I was allowed to have a part in the lesson rather than just sitting there and be drilled with facts and figures.  I’m not saying turn the class room into an arcade (for those of us old enough to know what that is) but, I don’t think there would be anything wrong with having a game or a simulator to make the class more interactive and fun for the students if the course subject permits it.</p>
    <br/>
    <p className="formatPage">When it comes to quick easy access to information, the world wide web is second to none.  There are countless amounts of free and paid sources of information that a student can access which can allow students to increase their marks significantly.  With today’s technological advancements, an instructor can now assign test and quizzes that can be done at a more convenient time for both the student and the instructor.  Of course, deadlines and preventative measures must be taken to prevent cheating, but in the University or College grind, this little use of technology can make a world of difference for the student who is taking a full course load with all the projects and assignments that come along with it.  Also, having the option to take advantage of today’s communication technology and sit through a lecture at home is a very wonderful thing when you must travel great distances to get to class.  For me personally, I always found that the quick feedback that an electronic test or quiz gave through automated marking was something I always welcomed.</p>
    <br/>
    <p className="formatPage">As good as technology is, if the instructor does not possess the necessary skills for teaching, the students will not learn.  If you give a skillful builder high quality wrenches and hammers they will produce a master piece.  Likewise, if you give the instructors use of quality technological tools, they will, like the builder, produce quality master pieces in the form of graduated students.</p>
    <br/>
  </div>
     </div>
     </div>
	<div className="row stop">
	<div className="col-md-12">
		<div className="fb-comments" data-href="http://Rectitude-Solutions.com/Blog" data-width="960" data-numposts="5"></div>
	</div>
	</div>
</div>
    </div>
  )
}
