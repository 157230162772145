import React,{useState} from 'react';
import axios from 'axios';
import Captcha from './CaptchaImage2';
import '../css/contactUs.css';

export default function ContactUs() {

    var toggle = true;
   
  // const urlGet = process.env.REACT_APP_API_PATH+"ContactUsController.php";
  const urlGet = "/api/ContactUsController.php";
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState({});
    const [reload, setReload] = useState(true);
    const [highlight, setHighlight] = useState(false);

    console.log(urlGet);
    
    
   
    const handleGetResponse = (captchaResponse,DBUserResponse ) =>{
            const regName = /^[a-zA-Z]*$/;
            const regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            const errorMessage = {
                Name:"first and last name fields can not contain any numbers or special characters and can not be left blank.",
                email:"A vaild email must be entered and can not be blank."
            };
            if(!regName.test(firstName) || !regName.test(lastName) || firstName == "" || lastName == ""){
                setMessageType({
                    showMessage:true,
                    alertType:"alert-danger",
                    message:errorMessage.Name,
                    color:"red",
                    error_code:"3"
             });
            }
            else if(!regEmail.test(email)){
                setMessageType({
                    showMessage:true,
                    alertType:"alert-danger",
                    message:errorMessage.email,
                    color:"red",
                    error_code:"4"
             });

            }
            else if(DBUserResponse == captchaResponse && DBUserResponse != "")
            {
                setHighlight(false);
                const data = {
                    firstName,
                    lastName,
                    email,
                    message
                };

                axios.post(urlGet,data).then((response)=>{
                    {   
                       if(response.data.error_message){
                           setMessageType({
                             showMessage:true,
                               alertType:"alert-success",
                                 message:"Your email was sent.",
                                 color:""
                        });
                        setReload(!reload);
                        setFirstName("");
                        setLastName("");
                        setEmail("");
                        setMessage("");
                        alert("Your email was sent.");
                       }
                       else{
                        setMessageType({
                            showMessage:true,
                            alertType:"alert-danger",
                              message:"There was a problem sending your email.",
                              color:"red",
                              error_code:"1"
                     });
                       }
                    }  
                }).catch((error)=>{
                    //console.log(error);
                });
            }
            else
            {
                setHighlight(true);
                setMessageType({
                    showMessage:true,
                    alertType:"alert-danger",
                    message:"There was a problem your captcha response.",
                    color:"red",
                    error_code:"2"
             });
            }
    }  

    const formatDiv = {
        height:"100px"
    }

    const handleOnChange = (e) =>{
        if(e.target.name == "firstName"){
        setFirstName(e.target.value);
        }

        if(e.target.name == "lastName"){
            setLastName(e.target.value);
        }

        if(e.target.name == "email"){
            setEmail(e.target.value);
        }

        if(e.target.name == "message"){
            setMessage(e.target.value);
        }
    }


  return (
    <div id="formJumbo" className="container-fluid stop">
          
          <div className="form-container">
                <h1 className="form-header">CONTACT US</h1>
                {messageType.showMessage ? <div className={"contactMessageFormat alert "+messageType.alertType+" "+messageType.color}>{messageType.message}</div>:""}
                    <label>FIRST NAME:</label>
                        <input type="text" name="firstName" onChange={handleOnChange} value={firstName} className="form-control" pattern="[a-zA-Z]+"  placeholder="Enter First Name" title="First Name must not contain any letters or special characters." />
                        <label>LAST NAME:</label>
                        <input type="text" name="lastName" onChange={handleOnChange} value={lastName} className="form-control" pattern="[a-zA-Z]+"  placeholder="Enter Last Name" title="Last Name must not contain any letters or special characters." />
                        <label>EMAIL:</label>
                        <input type="email" name="email" onChange={handleOnChange} value={email} className="form-control"  placeholder="Enter email" />    
                        <label>MESSAGE:</label>
                        <textarea class="form-control" name="message" onChange={handleOnChange} value={message} rows="5" id="message"></textarea>
                        <br/><br/>
                        <Captcha handleGetResponse={handleGetResponse}/>
          </div>
	</div>
  )
}
