import react from 'react';
import {  Route, Routes } from 'react-router-dom';
import Home2 from './Home2.js';
import AboutUs from './AboutUs';
import WebCreation from './WebCreation';
import Tutoring from './Tutoring';
import WebHosting from './WebHosting';
import ContactUs from './ContactUs';
import Blog from './Blog';
import FAQ from './FAQ';
import PageNotFound from './PageNotFound';
import NavBar from './NavBar';
import Footer from './Footer';



function App() {
  return (
    <div className="App">
      <NavBar/>
      <Routes>
        <Route element={<Home2/>} exact path="/"/>
        <Route element={<AboutUs/>} path="/AboutUs"/>
        <Route element={<WebCreation/>} path="/Web_Creation"/>
        <Route element={<Tutoring/>} path="/Tutoring"/>
        <Route element={<WebHosting/>} path="/Web_Hosting"/>
        <Route element={<ContactUs/>} path="/ContactUs"/>
        <Route element={<Blog/>} path="Blog"/>
        <Route element={<FAQ/>} path="FAQ"/>
        <Route path="*" element={<PageNotFound/>} /> 
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
