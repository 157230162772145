import React, {useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import '../css/homePageInfo.css';

export default function HomePageInfo() {

	const [divOn1,setDivOn1] = useState("image-format-div");
	const [divOn2,setDivOn2] = useState("image-format-div");
	const [divOn3,setDivOn3] = useState("image-format-div");
	const [divOn4,setDivOn4] = useState("services-text");
	const [divOn5,setDivOn5] = useState("services-text-info");
	const [divOn6,setDivOn6] = useState("video-div");
	const [divOn7,setDivOn7] = useState("h2-container");
	const [divOn8,setDivOn8] = useState("h2-container2");
	//var divOn = null;
const animationClass = [
	"servicesIcon-slideInRight",
	"servicesIcon-slideInRight2",
	"servicesIcon-slideInRight3",
	"serviceText-slideUp",
	"serviceText-fadeIn",
	"serviceText-fadeIn2"
];


$(window).scroll(function(){
	console.log($(this).scrollTop());
	if($(this).scrollTop()>842){
		setDivOn6("video-div "+animationClass[0]);
		setDivOn7("h2-container "+animationClass[5]);
		setDivOn8("h2-container2 "+animationClass[5]);
	}
	if($(this).scrollTop() > 318){
		setDivOn1("image-format-div "+animationClass[0]);
		setDivOn2("image-format-div "+animationClass[1]);
		setDivOn3("image-format-div "+animationClass[2]);
		setDivOn4("services-text "+animationClass[3]);
		setDivOn5("services-text-info "+animationClass[4]);
	}else if($(this).scrollTop() == 0){
		setDivOn1("image-format-div ");
		setDivOn2("image-format-div ");
		setDivOn3("image-format-div ");
		setDivOn4("services-text ");
		setDivOn5("services-text-info ");
		setDivOn6("video-div");
		setDivOn7("h2-container");
		setDivOn8("h2-container2");
	}
  });

/*if(documentElement.scrollY >= 318){
	console.log("in if block");
	setDivOn1("image-format-div "+animationClass[0]);
	}*/



/*useEffect(()=>{
	setDivOn(false);
},[])*/

  return (
    <div>
     
	<div class="container container-fluid background">

	<div id="services" class="services-div">
		<h2 className={divOn4}>Services</h2>
		<p className={divOn5}>From affordable website creation to software engineering tutoring services, Rectitude Solutions has the answer to your IT needs.</p>
		<p className={divOn5}>Browse our website to see what service you would like.</p><br/>
</div>


<div className="row">

	

	<div className="col-md-4 serviceBackground well" style={{backgroundColor:"transparent"}}>
	 <div className="thumbnail serviceBackground" style={{backgroundColor:"transparent"}}>
	<Link to="/Web_Creation">
	<div className={divOn1} style={{marginTop:"20px"}}><img  width="192" alt="" src="Rectitude_Logos/WebCreationIcon3.png"/></div>
		 <div className="caption">
          <p className="inconText">WEBSITE CREATION</p>
          </div>
          </Link>
        </div>
		</div>

	<div className="col-md-4 serviceBackground2  well">
	 <div className="thumbnail">
	<Link to="/Tutoring">
	<div className={divOn2}><img alt="" src="Rectitude_Logos/EducationIcon.png" height="187" width="192" /></div>
		<div className="caption">
          <p className="inconText">IT TUTORING</p>
        </div></Link></div>
        </div>

<div class="col-md-4 serviceBackground well" style={{backgroundColor:"transparent"}}>
 <div class="thumbnail serviceBackground" style={{backgroundColor:"transparent"}}>
<Link to="/Web_Hosting">
<div className={divOn3} style={{marginTop:"25px"}}><img className="rec-logos" alt="" src="Rectitude_Logos/WebHostingIcon.png"/></div>
		<div class="caption">
          <p class="inconText">WEB HOSTING</p>
        </div></Link></div>
			

</div>
</div>
 </div>
 <div className={divOn8}><h2 style={{textAlign:"center"}}>Watch the video to learn more about us</h2></div>
 <div className="video-container">

	 <div className={divOn6}>
	 <iframe className="video" src="/Video/Rectitude_Video2.mp4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	 </div>

	 <div className={divOn7}><h2>Watch the video<br/> to learn more about us</h2></div>

 </div>
		

 
    </div>
  )
}
