import React, {useState} from 'react';
import '../css/tutoring.css';
import {Link} from 'react-router-dom';
import $ from 'jquery';

export default function Tutoring() {

    const formatDiv = {
        height:"100px"
    }

	const [divOn1, setDivOn1] = useState("tutoring-header");
	const [divOn2, setDivOn2] = useState("tutoring-info-text");

	const animationClass = [
		"serviceText-slideUp",
		"serviceText-fadeIn"
	];

const textColor = {color:"black"};
$(window).scroll(function(){
	console.log($(this).scrollTop());
	if($(this).scrollTop() > 318){
		setDivOn1("tutoring-header "+animationClass[0]);
		setDivOn2("tutoring-info-text "+animationClass[1]);
		
	}else if($(this).scrollTop() == 0){
		setDivOn1("tutoring-header");
		setDivOn2("tutoring-info-text");
  };
 
});



  return (
      <div>
      
    <div id="tutoring" className="stop">
		<div className="format-div-tutoring"></div>
	<div className="jumbotron serviceText-fadeIn stop">
	
	    <h1 className="header-format">TUTORING SERVICES</h1>
	    <p className="tutoring-text" style={textColor}>We provide tutoring services for College and 1st to 2nd year University students</p>
	    <p className="tutoring-text" style={textColor}>High school students are also welcome.</p>
	    <p className="tutoring-text" style={textColor}>We provide affordable tutoring for $20/hr for in person and $15/hr for online tutoring.</p>
	    <br/>
		<Link to="/ContactUs"  className="btn btn-info" role="button">Book a Tutoring Session</Link>
	</div>
</div>

<div className="border-top stop">
<div className="info">
		<h2 className={divOn1}>Rectitude Solutions Tutoring Services</h2>
		<p className={divOn2}>Rectitude Solutions provides software egineering services for college and first to second year University students for $20/hr.
		These tutoring services are one on one in a school or local library.
		 We also provide online tutoring service for $15/hr.
		Payment can be made via paypal.  Students will be invoiced before the online tutoring session begins.
	    All tutoring sessions given by Rectitude Solutions are money back guaranteed.
		One on One in person tutoring is availble Tuesday, Wednesday and Friday.
		One on One in person tutoring is only available for people who live in the Greater Toronto, Ontario, Canada, area.</p>

</div>
</div>
</div>
  )
}
