import React, { useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleChevronUp, faDroplet} from '@fortawesome/free-solid-svg-icons';
import {faCircleChevronDown} from '@fortawesome/free-solid-svg-icons';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import MainBackgroundSection from './mainBackgroundSection';
import '../css/mainBackgroundSection.css';
import '../css/navbar.css';
import $ from 'jquery';

export default function NavBar() {



    const [dropdownToggle, setDropdownToggle] = useState({toggle:-1});
    const [dropdownToggle2, setDropdownToggle2] = useState({toggle:-1});
    var animation = "";
    var animation2 = "";

    const [dropDownColor, setDropDownColor] = useState("");
    const [style,setStyle] = useState({display:"none"});
    const [toggle, setToggle] = useState(false);
    const [divOn, setDivOn] = useState("dropdown-contents");
    const animationClass = [
      "serviceText2-fadeIn",
      "serviceText2-fadeOut"
    ];

    var textColor = {color:"#003366"}

    var linkStyle = {
      textDecoration:"none"
    }
  
   

    /* $('.elipses-container').click(function()
      {
          $('.dropdown-content').toggle('slow');
      });*/

    const menuOn = () =>{
      console.log("In menuOn");
      setToggle(!toggle);
      if(toggle){
        setDivOn("dropdown-contents-on "+animationClass[0]);
      }else{
        setDivOn("dropdown-contents "+animationClass[1]);
      }
    }

    const menuOff1 = ()=>{
      console.log("in menuOff1");
      setDivOn("dropdown-contents "+animationClass[1]);
    }

    const menuOff2 = ()=>{
      setToggle(true);
      menuOn();

      setDropdownToggle({toggle:-1})
      
    }

    
      
     
    $(window).scroll(function(){
      if($(this).scrollTop() > 100){
        linkStyle = {
        textDecoration:"none"
        } 
      }
    });




    useEffect(
    function(){
      /*$('.elipses-container').click(function()
      {
          $('.dropdown-content').toggle('slow');
      });*/


          $(window).scroll(function(){
            $('.navbar').toggleClass('scrolled', $(this).scrollTop() > 100);
            $('.under-line').toggleClass('scrolled', $(this).scrollTop() > 100);
            if($(this).scrollTop() > 100){
              linkStyle = {
              textDecoration:"none"
              } 
            }
          });
    },[]);

    function toggleDropdown() {
        if(dropdownToggle.toggle != 1){
        setDropdownToggle({toggle:1});
      }
      else{
        setDropdownToggle({toggle:0});
      }
    
  }

  function toggleDropdown2() {
    if(dropdownToggle2.toggle != 1){
    setDropdownToggle2({toggle:1});
  }
  else{
    setDropdownToggle2({toggle:0});
  }

}

  console.log("toggle value is "+dropdownToggle);


  /*$(window).scroll(function(){
    if($(this).scrollTop() > 100){
      setDropDownColor("white");
    }else{
      setDropDownColor("");
    }
  });*/
  if(dropdownToggle.toggle == 1){
    animation = "navbar-dropdown-fadeIn";
  }
  else if(dropdownToggle.toggle == 0){
    animation = "navbar-dropdown-fadeOut"
  }
  else{
    animation = "";
  }

  if(dropdownToggle2.toggle == 1){
    animation2 = "navbar-dropdown-fadeIn";
  }
  else if(dropdownToggle2.toggle == 0){
    animation2 = "navbar-dropdown-fadeOut"
  }
  else{
    animation2 = "";
  }

const format = {
  color:"white",
  fontSize:"20px"
}

const toggleOff = ()=>{
  setDropdownToggle2({toggle:0});
}

  return (
    <div>
    <nav class="navbar bg">
        
        
        
        <div className="logo-div"><Link to="/"><img src="Rectitude_Logos/Rectitude-Solution_Logo_small.png" className="logo-format"/></Link></div>
        <div className="elipses-container" onClick={()=>menuOn()}><FontAwesomeIcon icon={faEllipsisVertical} className="elipses"/></div>
        <div className="navbar-menu-container">
       
    
          <span className="nft navbar-text-format" onClick={()=>menuOff1()} ><Link to="/AboutUs" className="navbar-link" style={linkStyle}><p className="under-line">ABOUT US</p></Link></span>
        <span className="nft navbar-text-format" onClick={()=>toggleDropdown2()}>
            <p className="under-line">SERVICES</p>
            {dropdownToggle2.toggle == 1 ? <FontAwesomeIcon className="chevron-format" icon={faCircleChevronDown} /> : <FontAwesomeIcon className="chevron-format" icon={faCircleChevronUp} />}
            </span>


            <div className={"navbar-dropdown " + animation2}>
            {/*<div className="navbar-dropdown " style={style}>*/}
            <Link to="/Web_Creation" style={{textDecoration:"none",color:"black",width:"100%"}}> <div className={"dropdown-text "+dropDownColor} onClick={()=>toggleOff()}>Website Creation</div></Link>
            <Link to="/Tutoring" style={{textDecoration:"none", color:"black",width:"100%"}}><div className={"dropdown-text "+dropDownColor} onClick={()=>toggleOff()}>IT Tutoring</div></Link>
            <Link to="Web_Hosting" style={{textDecoration:"none",color:"black",width:"100%"}}><div className={"dropdown-text "+dropDownColor} onClick={()=>toggleOff()}>Web Hosting</div></Link>
            </div>
            
        
        <Link to="ContactUs" style={linkStyle}><span className="nft navbar-text-format" onClick={()=>menuOff1()}><p className="under-line">CONTACT US</p></span></Link>
            <Link to="Blog" style={linkStyle}> <span className="nft navbar-text-format2" onClick={()=>menuOff1()}><p className="under-line">BLOG</p></span></Link></div>
        <div className="icon-div" >
        <span><a href="https://www.linkedin.com/in/kevin-h-a27a9a85/">
            <img alt="Linked In Social Network Icon" src="Rectitude_Logos/LinkedInIcon_Black_bgWhite.png" height="47" className="auto-style1" width="37"/></a><a href="https://www.facebook.com/RectitudeSoftwareCreation/"><img alt="Facebook Social Icon" className="auto-style1" height="47" src="Rectitude_Logos/facebookIcon_BlackWhite.png" width="39"/></a><a href="https://twitter.com/RectitudeSolut4">
            <img alt="Twitter Social Media Icon" height="47" src="Rectitude_Logos/twitterIcon_BlackWhite.png" width="43"/></a><a href="https://www.youtube.com/channel/UCjoRXqaRnU4BudvOehc3RuA/"><img alt="Youtube Icon" style={{marginTop:"-5px"}} height="38" src="Rectitude_Logos/YoutubeIcon_Black_bgwhite.png" width="35"/></a></span>
	    </div>
{/**dropdown content */}
      <div className={divOn}>
              <div className="dropdown-text-div" onClick={()=>menuOff1()}><Link to={"/"} style={linkStyle}><p>HOME</p></Link></div>
<div className="dropdown-text-div" onClick={()=>menuOff1()}><Link to={"/AboutUs"} style={linkStyle}><p>ABOUT US</p></Link></div>

<div className="dropdown-text-div dropdown-format" onClick={()=>toggleDropdown()}><p>SERVICES
{dropdownToggle.toggle == 1 ? <FontAwesomeIcon className="chevron-format" icon={faCircleChevronDown} /> : <FontAwesomeIcon className="chevron-format" icon={faCircleChevronUp} />}
</p> 
    <div className={"navbar-dropdown2 " + animation}>
                <div className="dropdown-text" onClick={()=>menuOff1()}><Link to="/Web_Creation" style={{textDecoration:"none",color:"black"}}>WEBSITE CREATION</Link></div>
                <div className="dropdown-text" onClick={()=>menuOff1()}><Link to="/Tutoring" style={{textDecoration:"none",color:"black"}}>IT TUTORING</Link></div>
                <div className="dropdown-text" onClick={()=>menuOff1()}><Link to="/Web_Hosting" style={{textDecoration:"none",color:"black"}}>WEBSITE HOSTING</Link></div>
            </div>
    </div>
    
<div className="dropdown-text-div" onClick={()=>menuOff1()}><Link to={"/ContactUs"} style={linkStyle}><p>COUNTACT US</p></Link></div>
<div className="dropdown-text-div" onClick={()=>menuOff1()}><Link to={"/Blog"} style={linkStyle}><p>BLOG</p></Link></div>
</div>

    </nav>
    

    {/*<div className="format-nav-div" style={{height:"100px"}}></div>*/}
    </div>
  )
}