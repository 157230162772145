import React from 'react';
import '../css/Modal.css';

function HelpModal(props) {
    var showHideClassName = props.show ? "modal display-block" : "modal display-none";

    return (
         <div className={showHideClassName}>
      <div className="modal-main">
      <div className="close-button"><i class="fas fa-times-circle close-icon modal-close" onClick={() => props.hideModal()}></i></div>
        <div className="modal-header-div help-title">NEED HELP?</div>
                <p>When entering captcha response, make sure to enter ONLY LETTERS.</p>
                <p><b>EXAMPLE 1:</b> <span style={{backgroundColor:"green"}}>234<span className="example"><b>BUS</b></span>890</span></p>
                <p><b>EXAMPLE 2:</b>  <span style={{backgroundColor:"green"}}>23423<span className="example"><b>B</b></span>8<span className="example"><b>U</b></span>90<span className="example"><b>S</b></span></span></p> 
                <p>In both examples, only the blue <i>letters</i> are entered.</p>
                <p>Also, the captcha response is case sensitive.  This means <span className="example"><b>A<b/></b></span> is different from <span className="example"><b>a</b></span>.</p>
                <p>You must enter the letters in the <i>same case</i> you see them.</p>
      </div>
    </div>
  );
    
}

export default HelpModal
