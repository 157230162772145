import React from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocation, faPhone } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  return (
    <div className="container-fluid  stop" style={{borderTop:"black solid 20px",borderBottom:"#000d1a solid 20px;"}} id="footer_id">
    <div className="row">
        <div className="col-sm-6 col-md-4 footer_class footer_justify">
    
            <img alt="Rectitude-Solution footer logo" src="Rectitude_Logos/Rectitude-Solution_Logo_border.png"/>
            <p><br/> <Link className="footer-links"  to='/' style={{textDecoration:"none"}}>Home</Link> - <Link className="footer-links"  to='/AboutUs' style={{textDecoration:"none"}}>About Us</Link> - <Link className="footer-links"  to='/Web_Creation' style={{textDecoration:"none"}}> Webcreation</Link> - <Link className="footer-links"  to='/Tutoring' style={{textDecoration:"none"}}> Tutoring</Link><br/>  <Link className="footer-links"  to='/Web_Hosting' style={{textDecoration:"none"}}> Webhosting</Link> - <Link className="footer-links" style={{textDecoration:"none"}}to='/ContactUs'> Contact Us</Link> -
            <Link className="footer-links"  to='/Blog' style={{textDecoration:"none"}}> Blog </Link> - <Link className="footer-links" to='/FAQ' style={{textDecoration:"none"}}>FAQ</Link><br/><br/></p>

            <h6>&copy;Copyright Rectitude Solutions 2022</h6>
            </div>
    
    <div className="col-sm-6 col-md-4 footer_class footer_justify" style={{paddingTop:"100px"}}>
        <p><FontAwesomeIcon icon={faLocation}/>Toronto, Ontario, Canada<br/><br/></p>
    
        <p><FontAwesomeIcon icon={faPhone}/>  (416) 570-7647<br/><br/></p>
    
    
    <p><FontAwesomeIcon icon={faEnvelope}/><span id="footer_blue">  Kevin_Haynes@Rectitude-Solutions.com</span><br/><br/></p>
    
    
    
            </div>
    
        <div className="col-sm-12 col-md-4 footer_class footer_justify longer-column">
        <p>About the company<br/><br/></p>
        <p>Rectitude (Rightouess) Solutions was founded by Kevin Haynes who is a Software Engingeering Technician graduate from Centennial College. Our aim is to create affordable applications while
        maintaining our social responsiblity to society.</p><p>  Our motto is "We help you find the right solutions." </p>
        <span><a href="https://www.linkedin.com/in/kevin-h-a27a9a85/">
            <img alt="Linked In Social Network Icon" src="Rectitude_Logos/LinkedInIcon_Black_bgWhite.png" height="47" className="auto-style1" width="37"/></a><a href="https://www.facebook.com/RectitudeSoftwareCreation/"><img alt="Facebook Social Icon" className="auto-style1" height="47" src="Rectitude_Logos/facebookIcon_BlackWhite.png" width="39"/></a><a href="https://twitter.com/RectitudeSolut4">
            <img alt="Twitter Social Media Icon" height="47" src="Rectitude_Logos/twitterIcon_BlackWhite.png" width="43"/></a><a href="https://www.youtube.com/channel/UCjoRXqaRnU4BudvOehc3RuA/"><img alt="Youtube Icon" style={{marginTop:"-5px"}} height="38" src="Rectitude_Logos/YoutubeIcon_Black_bgwhite.png" width="35"/></a></span>
    <p><br/></p>
        </div>
    
    
    </div>
    </div>
    
  )
}
